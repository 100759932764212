var mena = window.mena || {};

(function ($) {
    'use strict';

    mena.main.init = function () {
        mena.main.svg4everybody();
          mena.main.mobileMenu();

    };

    mena.main.svg4everybody = function() {
        svg4everybody();
    }


      mena.main.mobileMenu = function() {

        var respMenu = $( '.resp-menu' );
        var button   = $( '.js-menu-toggle' );
        var body     = $( 'body' );

        button.click(function(e) {
            body.toggleClass('menu-open');
            button.toggleClass('menu-toggle--active');
        });

        /* Adding submenu arrows to the responsive navigation */
        respMenu.find('.sub-menu').each(function() {
            $(this).parent().append('<a class="submenu-button" href="javascript:void(0)"><svg class="arrow-down" width="15px" height="8px"><polygon fill="#fff" points="15.002,0 7.501,7.501 0,0 "></polygon></svg></a>');
        });

        $('.submenu-button').click(function() {
            $('.sub-menu').not($(this).siblings()).slideUp(300);
            $(this).prev('.sub-menu').slideToggle(100);
            $(this).toggleClass('rotate-arrow');
        });

        $('.menu-item a').on('click',function(){
             body.toggleClass('menu-open');
            button.toggleClass('menu-toggle--active');
        })

    };

return mena.main.init();

}($));